<template>
  <LocalizedText :i18n-key="i18nKey" :i18n-args="i18nArgs" />
</template>

<script setup lang="ts">
import type { PrimitiveType } from 'intl-messageformat'
import { hydrateOnVisible } from 'vue'

type Props = {
  i18nKey: string
  i18nArgs?: Record<string, PrimitiveType>
}

withDefaults(defineProps<Props>(), {
  i18nArgs: undefined,
})

const LocalizedText = defineAsyncComponent({
  loader: () => import('./LocalizedText.vue'),
  hydrate: hydrateOnVisible(),
})
</script>
